<template>
  <form class="control-panel-form"
    @submit.prevent="handleSubmit"
    @reset.prevent="handleReset"
  >
    <div class="inputs-container">
      <WInput md
        customClass="control-panel-input"
        id="logs-number"
        :label="$t('logs.numberOfLogs')"
        name="logs-number"
        type="number"
        min="1"
        max="5000"
        v-model="formData.limit"
        :disabled="isDisabled"
        data-vv-validate-on="input"
        v-validate="'min_value:1|max_value:5000'"
        :data-vv-as="$t('logs.numberOfLogs')"
      />

      <WInput md
        customClass="control-panel-input"
        id="searchText"
        :label="$t('general.search')"
        name="searchText"
        v-model.trim="formData.searchText"
        :disabled="isDisabled"
        data-vv-validate-on="input"
        :data-vv-as="$t('general.search')"
      />

      <WSelect md
        customClass="control-panel-input"
        id="search-container"
        :label="$t('logs.filter')"
        v-model="formData.searchContainer"
        :options="searchContainers"
      />
    </div>

    <div class="buttons-container">
      <WButton info
        type="submit"
        :disabled="isDisabled || errors.any()"
      >
        {{ isDisabled ? $t('general.state.loading') : $t('general.view') | capitalize }}
      </WButton>
      <WButton info
        type="reset"
        :disabled="isDisabled"
      >
        {{ $t('general.clear') | capitalize }}
      </WButton>
      <WButton info
        @click="handleDownloadTxt"
        :disabled="isDisabled"
      >
        {{ $t('general.download') | capitalize }}
      </WButton>
    </div>
  </form>
</template>

<script>
/**
 * компонент - форма управления таблицей для страницы логов Logs.vue.
 * компонент локальный: используется только в Logs.vue.
 * @component
*/

export default {
  name: 'ControlPanel',
  components: {
  },
  props: {
    defaultFormValues: {
      type: Object,
      required: true
    },
    searchContainers: {
      type: Array,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formData: { ...this.defaultFormValues }
    };
  },
  watch: {
  },
  computed: {
  },
  methods: {
    async handleSubmit() {
      const isFormValid = await this.$validator.validateAll();

      if (!isFormValid) {
        return;
      }

      this.$emit('submit', this.formData);
    },
    handleReset() {
      this.formData = { ...this.defaultFormValues };
    },
    handleDownloadTxt() {
      this.$emit('downloadTxt');
    }
  }
};
</script>

<style lang="css" scoped>
.control-panel-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 8px 32px;
  padding: 8px 0;
}

.inputs-container {
  flex: 1 0 40%;
  display: flex;
  gap: 8px;
  transform: translateY(-17%);
}

.inputs-container > div:nth-of-type(1){
  flex: 1 1 20%;
}

.inputs-container > div:nth-of-type(2) {
  flex: 1 1 45%;
}

.inputs-container > div:nth-of-type(3) {
  flex: 1 1 35%;
}

.buttons-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

</style>
<style lang="css">
/*
  кастомные стили для WInput
  todo после того как все инпуты в проекте изменяться на WInput, отрефакторить и удалить
*/
.control-panel-input {
  border-radius: 8px !important;
}
</style>
