<template>
  <v-popover
    trigger="hover"
    placement="top-center"
    popoverClass="reactive-logs-popover">
<!--    <a @click.stop :href="href" target="_blank">-->
<!--      {{ fileName }}-->
<!--    </a>-->
    <span @click.stop.prevent="downloadReport(url, fileName)" class="ml-q" role="button">
      <a :href="href" class="fa fa-download"></a>
    </span>
    <template slot="popover">
        <ul class="list-unstyled mb-h reactive-logs-popover-content">
          <li><b>{{ $t('reactiveLogs.startTime') }}:</b></li>
          <li>{{ formatDatetime(startAt) }}</li>
          <li><b>{{ $t('reactiveLogs.endTime') }}:</b></li>
          <li>{{ formatDatetime(stopAt) }}</li>
        </ul>
    </template>
  </v-popover>
</template>

<script>
/**
 * компонент - тултип фильтра реактивных логов.
 * компонент локальный: используется только в LogFilterTable.vue
 * @component
 */
import reactiveLogsApi from '../../../../api';

export default {
  name: 'ReactiveLogsTooltip',
  components: {},
  props: {
    fileName: {
      String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    startAt: {
      type: Number,
      required: true
    },
    stopAt: {
      type: Number,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  methods: {
    formatDatetime(timestamp) {
      return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    },
    downloadReport(url, fileName) {
      reactiveLogsApi.downloadReport({ url, fileName });
    }
  }
};
</script>

<style lang="css">
.reactive-logs-popover {
  border: none;
  padding: 0;
  margin: 0;
}

.reactive-logs-popover-content {
  display: flex;
  flex-direction: column;
  text-align: left;
}
</style>
